function loadExternalScript(url) {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    window.chatbotConfig = {
        bot_id: 'bca5b8df-005b-4fa5-8fd8-bb85ef61de16',
        organization: 'Empirx Dev Bot',
        botScriptUrl: 'https://black-desert-04898fc0f.5.azurestaticapps.net/',
    };
    script.onload = () => {
        console.log('Script loaded successfully');
    };
    script.onerror = (error) => {
        console.error('Error loading script:', error);
    };
    document.body.appendChild(script);
}

loadExternalScript('https://black-desert-04898fc0f.5.azurestaticapps.net/widget-loader.js');